import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../common/enums/api-status";
import { BaseState } from "../../common/models/base-state";
import { checkUserExistsAsync, confirmEmailAsync, forgotPasswordAsync, loginAsync, registerAsync, resendConfirmationEmailAsync, resetPasswordAsync } from "./securityServiceThunk";
import LocalStorageService from "../../../infrastructure/local-storage-service";
import { CurrentUser } from "./models/current-user-dto";
import { getCurrentUserFromJwt } from "../../common/helpers/jwt-helper";


export interface SecurityServiceState extends BaseState {
    currentUser?: CurrentUser;
    isShowCompleteRegisteration: boolean;
}

const initialState: SecurityServiceState = {
    status: ApiStatus.IDLE,
    currentUser: getCurrentUserFromJwt(),
    isShowCompleteRegisteration: false
}

export const securityServiceSlice = createSlice({
    name: 'securityService',
    initialState,
    reducers: {
        logout(state) {
            state.currentUser = undefined;
            LocalStorageService.removeAccessToken();
            state.apiError = undefined;
            state.status = ApiStatus.IDLE;
        },
        setIsShowCompleteRegisteration(state, action: PayloadAction<boolean>) {
            state.isShowCompleteRegisteration = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
                LocalStorageService.setAccessToken(action.payload.access_token);
                // LocalStorageService.setUserRole(action.payload.USER_ROLE);
            })
            .addCase(loginAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            });

        builder
            .addCase(registerAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(registerAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(registerAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder
            .addCase(confirmEmailAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(confirmEmailAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(confirmEmailAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })


        builder
            .addCase(forgotPasswordAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(forgotPasswordAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder
            .addCase(resetPasswordAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(resetPasswordAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(resetPasswordAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })
        builder
            .addCase(checkUserExistsAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(checkUserExistsAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(checkUserExistsAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

        builder
            .addCase(resendConfirmationEmailAsync.pending, (state) => {
                state.status = ApiStatus.PENDING;
                state.apiError = undefined;
            })
            .addCase(resendConfirmationEmailAsync.fulfilled, (state, action) => {
                state.status = ApiStatus.IDLE;
            })
            .addCase(resendConfirmationEmailAsync.rejected, (state, action) => {
                state.status = ApiStatus.FAILED;
                state.apiError = action.payload;
            })

    }
})

export const { logout, setIsShowCompleteRegisteration } = securityServiceSlice.actions;
export default securityServiceSlice.reducer;